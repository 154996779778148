$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// scss-docs-start color-variables
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// custom variables
$primary: #014f86;
$secondary: #ffaa00;
$success: #0d8012;
$info: #0062f5;
$warning: #cc3300;
$danger: #b00020;
$light: $gray-100;
$dark: $gray-900;
$lightBLue: #b8dae8;
$formBlueHover: #e6f4f7;
$formBlueFocus: #a9d6e5;
$formRed: #bf2b45;
$formRedHover: #ffe5e6;
$formRedFocus: #ffc3c5;

$body-bg: $black;
$body-color: $black;

$primary-100: tint-color($primary, 80%);
$primary-200: tint-color($primary, 60%);
$primary-300: tint-color($primary, 40%);
$primary-400: tint-color($primary, 20%);
$primary-500: $primary;
$primary-600: shade-color($primary, 20%);
$primary-700: shade-color($primary, 40%);
$primary-800: shade-color($primary, 60%);
$primary-900: shade-color($primary, 80%);

$secondary-100: tint-color($secondary, 80%);
$secondary-200: tint-color($secondary, 60%);
$secondary-300: tint-color($secondary, 40%);
$secondary-400: tint-color($secondary, 20%);
$secondary-500: $secondary;
$secondary-600: shade-color($secondary, 20%);
$secondary-700: shade-color($secondary, 40%);
$secondary-800: shade-color($secondary, 60%);
$secondary-900: shade-color($secondary, 80%);
